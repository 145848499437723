<template>
  <!-- 币种管理 -->
  <div class="container">
    <!-- 按钮列表 -->
    <div class="btnList1" ref="btnList">
      <el-button
        v-if="hasPermiss('jcsz_biZhongGuanLi:add')"
        class="itemBtn btnColor"
        @click="apply"
        >新建</el-button
      >
      <el-button
        v-if="hasPermiss('jcsz_biZhongGuanLi:update')"
        class="itemBtn btnColor"
        @click="modifybut"
        >修改</el-button
      >
      <el-button
        v-if="hasPermiss('jcsz_biZhongGuanLi:del')"
        class="itemBtn btnColor"
        @click="CancelPayment"
        >删除</el-button
      >

      <el-button class="itemBtn btnColor" @click="myModel">列表设置</el-button>
      <el-dropdown @command="handleCommand_dc">
        <el-button class="dropdownBtn">
          导出<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="dc">导出</el-dropdown-item>
          <el-dropdown-item command="dcmb">下载导出模板</el-dropdown-item>
          <el-dropdown-item command="scmb">保存(上传)模板</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <!-- 查询条件 -->

    <!-- 表格 -->
    <div
      class="tableBox"
      ref="tableWrapper"
      :class="{ tableBox: TableStyle, tableBox1: fullscreen }"
    >
      <el-table
        :height="tableHeigth"
        ref="mytable"
        :data="tableData"
        show-summary
        @row-click="rowclick"
        :summary-method="getSummaries"
        :row-class-name="tableRowClassName"
        highlight-current-row
        :header-cell-style="{ background: '#f0f0f0' }"
        border
        @selection-change="handleSelectionChange"
      >
        <el-table-column fixed="left" label="序号" type="index" width="60">
        </el-table-column>
        <el-table-column fixed="left" type="selection" width="60">
        </el-table-column>
        <template v-for="(item, index) in myTableHeard">
          <el-table-column
            :key="index"
            :label="item.name"
            sortable
            :prop="item.field"
            :min-width="item.width"
            :data-isTotal="item.isTotal"
            v-if="item && item.isShow"
          >
          </el-table-column>
        </template>
      </el-table>
    </div>
    <sorttable
      :show_sortTableHeard="show_sortTableHeard"
      :allCheck="allCheck"
      :sortName="sortName"
      :tabelHeadeTitle="tabelHeadeTitle"
      :originalTabelHeadeTitle="originalTabelHeadeTitle"
      :selectLength="selectLength"
      @cancelSortHeard="cancelSortHeard"
      @confirmOk="confirmOk"
      @checktab="checktab"
      @checkAll="checkAll"
      @closeDias="closeDias"
    ></sorttable>
    <!-- //排序 -->

    <!-- 新增修改 -->
    <div>
      <el-dialog
        :title="title"
        :visible.sync="NewWarehouse"
        :before-close="WarehouseClose"
      >
        <div>
          <el-form
            class="newForm"
            label-width="130px"
            :rules="rules"
            status-icon
            :model="Warehousearray"
            ref="Warehousearray"
          >
            <div
              class="queryItem1"
              style="width: 100%"
              v-if="pageType == 'add'"
            >
              <el-form-item label="新增方式:" prop="addWay">
                <el-radio-group v-model="addWay">
                  <el-radio label="a">已知币种选择</el-radio>
                  <el-radio label="b">新增币种</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
            <div class="queryItem1" v-if="addWay == 'a'">
              <el-form-item label="货币名称:" prop="cnName">
                <el-select
                  :disabled="pageType == 'edit'"
                  @change="changeHuoBi"
                  clearable
                  filterable
                  v-model="Warehousearray.cnName"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="(item, index) in huoBiList"
                    :key="index"
                    :label="item.currencyName"
                    :value="item.currencyName"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="queryItem1" v-if="addWay == 'b'">
              <el-form-item label="货币名称:" prop="cnName">
                <el-input
                  v-model="Warehousearray.cnName"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </div>
            <div class="queryItem1">
              <el-form-item label="货币缩写:">
                <el-input
                  v-model="Warehousearray.abbrName"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </div>
            <div class="queryItem1">
              <el-form-item label="币种符号:" prop="symbol">
                <el-input
                  v-model="Warehousearray.symbol"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </div>
            <div class="queryItem1" style="width: 100%">
              <el-form-item label="币种汇率获取:" prop="isAuto">
                <el-radio-group
                  v-model="Warehousearray.isAuto"
                  @change="changeRadio"
                >
                  <el-radio label="1">自动获取</el-radio>
                  <el-radio label="0">手动设置</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
            <div class="queryItem1">
              <el-form-item label="汇率:" prop="exchangeRate">
                <el-input
                  :disabled="Warehousearray.isAuto == 1"
                  v-model="Warehousearray.exchangeRate"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </div>
            <!-- <div class="queryItem1" v-if="Warehousearray.isAuto == '0'">
              <el-form-item label="固定金额:" prop="exchangeRate">
                <el-input
                  v-model="Warehousearray.exchangeRate"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </div> -->
            <div style="margin: 20px 10px">
              汇率备注：新币种兑换人民币的汇率：比如 1美元=7.169人民币，这里填
              7.169
            </div>
            <div class="queryItem1">
              <el-form-item label="出厂配置:" prop="isInitialization">
                <el-select
                  v-model="Warehousearray.isInitialization"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="(item, index) in zhanshiList"
                    :key="index"
                    :label="item.value"
                    :value="item.id + ''"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="queryItem1">
              <el-form-item label="启用状态:" prop="isShow">
                <el-select v-model="Warehousearray.isShow" placeholder="请选择">
                  <el-option
                    v-for="(item, index) in zhanshiList"
                    :key="index"
                    :label="item.value"
                    :value="item.id + ''"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="queryItem1">
              <el-form-item label="排序:" prop="sort">
                <el-input-number
                  v-model="Warehousearray.sort"
                  :min="1"
                  :max="12000"
                ></el-input-number>
              </el-form-item>
            </div>
            <div class="queryItem1" style="width: 100%">
              <el-form-item label="备注:">
                <el-input
                  type="textarea"
                  v-model="Warehousearray.remarks"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </div>
          </el-form>
        </div>
        <div class="footerbox">
          <el-button class="seniorbut" @click="PaymentConfirmation"
            >保存</el-button
          >
        </div>
      </el-dialog>
    </div>
    <!-- 分页组件 -->
    <paging
      ref="pags"
      :pageNum="pageNum"
      :total="total"
      :sizeList="sizeList"
      :size="size"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    ></paging>

    <!-- 自定义排序表头 -->
    <div>
      <el-dialog
        title="上传编辑好的导出模板"
        :visible.sync="show_upload"
        append-to-body
        width="40%"
        center
      >
        <div>
          <el-upload
            :action="uploadUrl"
            :show-file-list="true"
            :file-list="fileList"
            :limit="1"
            :http-request="uploadSectionFile"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">上传模板</div>
          </el-upload>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="show_upload = false">关 闭</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { Api } from "@/assets/js/api";
import tools from "@/assets/js/tool";
import paging from "@/components/pagings.vue";
import sorttable from "@/components/sortTemplate.vue";

export default {
  inject: ["reload"],
  components: {
    paging,
    sorttable,
  },
  data() {
    return {
      time1: "",
      time2: "",
      FormData: {
        aaa: "",
      },
      value: "",

      // ================
      pageNum: 1, //页码
      total: 0, //总条数
      sizeList: [10, 20, 50, 100, 200],
      size: 50, //一页几条
      nextPage: false, //是否有下一页
      show_sortTableHeard: false, //显示弹窗
      show_upload: false, //显示上传弹窗
      myTableHeard: [], //我的表头
      originalTabelHeadeTitle: [],
      selectLength: 0, //显示的长度
      allCheck: false, //全选
      tableHeigth: "50vh",
      tabelHeadeTitle: [
        {
          name: "币种",
          field: "cnName",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
        },

        {
          name: "币种简称",
          field: "abbrName",
          width: "120",
          sort: 2,
          isShow: true,
          isTotal: false,
        },
        {
          name: "币种符号",
          field: "symbol",
          sort: 3,
          isShow: true,
          width: "120",
          isTotal: false,
        },
        {
          name: "币种汇率获取方式",
          field: "isAutoShow",
          width: "160",
          sort: 4,
          isShow: true,
          isTotal: false,
        },
        {
          name: "兑人民币汇率",
          field: "exchangeRate",
          width: "160",
          sort: 6,
          isShow: true,
          isTotal: false,
        },
        {
          name: "出厂设置",
          field: "isInitializationShow",
          width: "120",
          sort: 6,
          isShow: true,
          isTotal: false,
        },
        {
          name: "启用状态",
          field: "isShowShow",
          width: "100",
          sort: 4,
          isShow: true,
          isTotal: false,
        },
        {
          name: "排序",
          field: "sort",
          sort: 3,
          isShow: true,
          width: "100",
          isTotal: false,
        },
        {
          name: "备注",
          field: "remarks",
          sort: 3,
          isShow: true,
          width: "160",
          isTotal: false,
        },
        {
          name: "修改时间",
          field: "updateTime",
          sort: 3,
          isShow: true,
          width: "160",
          isTotal: false,
        },
        {
          name: "修改人",
          field: "updateUserName",
          sort: 3,
          isShow: true,
          width: "120",
          isTotal: false,
        },
      ],
      title: "",
      tableData: [],
      totalArr: [], //需要合计的字段
      className: "", //导出需要
      fieldName: [], //下载字段名
      head: [], //下载表头字段
      fileList: [],
      uploadUrl: "", //上传路径
      sortName: "模板名", //排序名
      pageGroupName: "huobiguanli", //页面标识
      selectTableData: [], //选中的数据
      //===============
      pageNum_member: 1, //页码
      isActive: true, //输入框绑定动态class
      judge: false, //输入框绑定动态class
      MoreConditions: "更多条件", //输入框绑定动态class
      fullscreen: false,
      TableStyle: true,
      cangkuList: [], //仓库下拉列表
      bumenList: [], //部门下拉列表
      yuangongList: [], //员工下拉列表
      NewWarehouse: false, //新建修改仓库弹窗
      pageType: "", //修改删除状态
      bankId: "", //银行id
      zhanshiList: [
        {
          value: "是",
          id: 1,
        },
        {
          value: "否",
          id: 0,
        },
      ],
      rules: {
        cnName: [{ required: true, message: "请输入", trigger: "blur" }],
        exchangeRate: [{ required: true, message: "请输入", trigger: "blur" }],
        isShow: [
          { required: true, message: "请选择", trigger: ["blur", "change"] },
        ],
        isAuto: [
          { required: true, message: "请选择", trigger: ["blur", "change"] },
        ],
        isInitialization: [
          { required: true, message: "请选择", trigger: ["blur", "change"] },
        ],
        sort: [{ required: true, message: "请输入", trigger: "blur" }],
        symbol: [{ required: true, message: "请输入", trigger: "blur" }],
      },
      addWay: "a", //新增币种方式
      Warehousearray: {
        cnName: "", //货币中文名称
        enName: "", //货币英文名称
        isShow: "1", //是否展示
        sort: "1", //排序
        isAuto: "1",
        exchangeRate: "",
        isInitialization: "0",
        symbol: "",
        abbrName: "",
        code: "",
        symbol: "",
        enName: "",
        region: "",
        remarks: "",
      },
      huoBiList: [],
    };
  },
  updated() {
    if (this.show_sortTableHeard) {
      // 排序表头
      let arr = this.tabelHeadeTitle;
      for (var i = 0; i < arr.length; i++) {
        arr[i].sort = i + 1;
      }
      this.tabelHeadeTitle = arr;
    }
  },
  created() {
    this.uploadUrl = this.common.uploadUrl;
    if (this.originalTabelHeadeTitle.length == 0) {
      this.originalTabelHeadeTitle = JSON.parse(
        JSON.stringify(this.tabelHeadeTitle)
      );
    }
    this.getGroupFindFieldSort(); //获取用户自定义表头
    // this.myTableHeard = this.tabelHeadeTitle;

    this.endtime = tools.getCurrentDate(); //今天的时间;
    // this.endtime = tools.getFlexDate(1); //今天+1的时间;
    this.sarttime = tools.getFlexDate(-3); //三天前
    this.times = [this.sarttime, this.endtime];
  },
  mounted() {
    const that = this;
    this.getList();
    this.getData();
    this.getStatusValList("finance_in_record_v1.approve_status") || []; //审核状态列表
  },
  watch: {},
  methods: {
    rowclick(row, event, column) {
      this.$refs.mytable.toggleRowSelection(row);
    },
    // 获取已知货币下拉列表
    getHasCurrencyDownList(name) {
      Api.getHasCurrencyDownList({ name: name || "" }).then((res) => {
        let datas = res.data.result || [];
        this.huoBiList = datas;
      });
    },
    // 选择货币时触发
    changeHuoBi(e) {
      console.log(e);
      this.huoBiList.forEach((item) => {
        if (item.currencyName == e) {
          this.Warehousearray.exchangeRate = item.exchangeRate;
        }
      });
    },
    // 选择改变汇率方式
    changeRadio(e) {
      console.log(e);
      if (e == 1) {
        this.huoBiList.forEach((item) => {
          if (item.currencyName == this.Warehousearray.cnName) {
            this.Warehousearray.exchangeRate = item.exchangeRate;
          }
        });
      }
    },

    //员工弹窗关闭
    WarehouseClose() {
      this.$refs.Warehousearray.resetFields();
      this.Warehousearray = {
        cnName: "", //货币中文名称
        enName: "", //货币英文名称
        isShow: "1", //是否展示
        sort: "1", //排序
        isAuto: "1",
        exchangeRate: "",
        isInitialization: "0",
        symbol: "",
        abbrName: "",
        code: "",
        symbol: "",
        enName: "",
        region: "",
        remarks: "",
      };
      this.addWay = "a";
      this.NewWarehouse = false;
    },
    //新建仓库
    apply() {
      this.title = "新建";
      this.pageType = "add";
      this.NewWarehouse = true;
      this.getHasCurrencyDownList();
    },
    //修改
    modifybut() {
      this.getHasCurrencyDownList();
      this.title = "修改";
      this.pageType = "edit";
      let selectTableData = this.selectTableData;
      if (selectTableData.length != 1) {
        this.$message.warning("请勾选一条数据");
      } else {
        this.currencyId = selectTableData[0].currencyId;
        Api.currencyDetail({ currencyId: this.currencyId }).then((res) => {
          //console.log(res.data.result)
          this.Warehousearray = res.data.result || [];
          this.Warehousearray.isAuto = this.Warehousearray.isAuto + "";
          this.Warehousearray.isInitialization =
            this.Warehousearray.isInitialization + "";
          this.Warehousearray.isShow = this.Warehousearray.isShow + "";

          if (this.Warehousearray.isAuto == "1") {
            this.huoBiList.forEach((item) => {
              if (item.currencyName == this.Warehousearray.cnName) {
                this.Warehousearray.exchangeRate = item.exchangeRate;
              }
            });
          }
          delete this.Warehousearray.isShowShow;
        });
        this.NewWarehouse = true;
      }
    },
    //新建修改保存
    PaymentConfirmation() {
      this.$refs.Warehousearray.validate((valid) => {
        if (valid == false) {
          return;
        } else {
          //新建
          let _this = this;
          // let Warehousearray = this.Warehousearray;
          let param = {
            cnName: this.Warehousearray.cnName || "",
            exchangeRate: this.Warehousearray.exchangeRate || "",
            isAuto: this.Warehousearray.isAuto || "0",
            isInitialization: this.Warehousearray.isInitialization || "0",
            isShow: this.Warehousearray.isShow || "0",
            sort: this.Warehousearray.sort || "1",
            symbol: this.Warehousearray.symbol || "",
            abbrName: this.Warehousearray.abbrName || "",
            code: this.Warehousearray.code || "",
            enName: this.Warehousearray.enName || "",
            region: this.Warehousearray.region || "",
            remarks: this.Warehousearray.remarks || "",
          };

          if (this.pageType == "add") {
            let sign = tools.getSign(param);
            param.sign = sign;
            Api.addCurrencyList(param).then((res) => {
              if (res.data.status == "success") {
                this.$message.success(res.data.message || "操作成功");
                this.NewWarehouse = false;
                _this.getData();
                _this.WarehouseClose();
              }
            });
          } else {
            //修改
            param.currencyId = this.Warehousearray.currencyId || "";
            let sign = tools.getSign(param);
            param.sign = sign;
            Api.updateCurrencyList(param).then((res) => {
              if (res.data.status == "success") {
                this.$message.success(res.data.message || "操作成功");
                this.NewWarehouse = false;
                _this.getData();
                _this.WarehouseClose();
              }
            });
          }
        }
      });
    },
    //删除
    CancelPayment() {
      let _this = this;
      let selectTableData = this.selectTableData;
      let currencyIds = [];
      let array = {};
      if (selectTableData.length < 1) {
        this.$message.warning("请至少勾选一条数据");
      } else {
        for (var i = 0; i < selectTableData.length; i++) {
          currencyIds.push(parseInt(selectTableData[i].currencyId));
        }
        array.currencyIds = currencyIds;
        array.sign = tools.getSign(currencyIds);
        //console.log(array)
        this.$confirm("是否确定删除勾选的数据", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          Api.deleteCurrency(array).then((res) => {
            if (res.data.status == "success") {
              this.$message.success(res.data.message || "操作成功");
              _this.getData();
            }
          });
        });
      }
    },
    //获取下拉列表
    getList() {
      //获取仓库下拉列表
      Api.getStorageList().then((res) => {
        this.cangkuList = res.data.result || [];
      });
      Api.departmentChooseList().then((res) => {
        if (res.data.status == "success") {
          this.bumenList = res.data.result;
        }
      });
      Api.employeeChooseList().then((res) => {
        if (res.data.status == "success") {
          this.yuangongList = res.data.result;
        }
      });
    },

    //表格样式i
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 === 0) {
        return "warning-row";
      }
    },
    // 获取状态展示值
    getStatusValList(status) {
      // 包裹状态: ==========> package_v1.package_status
      // 拒收单拒收状态: ====> bill_rejection_v1.handle_status
      // 验货单验货状态: ====> bill_inspection_v1.check_status
      // 审批状态:==========> finance_in_record_v1.approve_status
      // 核销状态:==========> finance_in_record_v1.writeoff_status
      // 单据类型:==========> finance_in_record_v1.document_status
      // 打包状态:==========> bill_pack_v1.pack_status
      // 服务类型:==========> item_category_v1.service_status
      // 费项类型:==========> item_service_v1.direction_status
    },

    handleCommandTabs(m) {
      if (m == "a") {
        this.myModel();
      } else if (m == "b") {
        this.exportBtn();
      } else if (m == "c") {
        this.exportTemplateDownload();
      } else if (m == "d") {
        this.upLoadBtn();
      } else {
        this.screen();
      }
    },
    //全屏模式
    screen() {
      // if (screenfull.isEnabled) {
      //     screenfull.toggle(this.$refs.tableWrapper);
      // }
    },
    // 阻止F11默认事件
    keydown(event) {
      if (event.keyCode === 122) {
        event.preventDefault();
        event.returnValue = false;
      }
    },

    /**
     * 响应屏幕变化事件，给isFullscreen赋值，标识是否全屏
     * IE中bug: 调试器打开，且与页面不分离时 -- 无效
     * **/
    onresize(event) {
      // 利用屏幕分辨率和window对象的内高度来判断兼容IE
      let winFlag = window.innerHeight === window.screen.height;
      // 利用window全屏标识来判断 -- IE无效
      let isFull = window.fullScreen || document.webkitIsFullScreen;

      if (isFull === undefined) {
        this.isFullscreen = winFlag;
      } else {
        this.isFullscreen = winFlag || isFull;
      }
      //console.log('winFlag || isFull', winFlag, isFull)
      if (winFlag == true && isFull == true) {
        this.fullscreen = true;
        this.TableStyle = false;

        //console.log(this.tableHeigth)
      } else {
        this.fullscreen = false;
        this.TableStyle = true;
      }
    },

    //输入框绑定动态class
    more() {
      this.isActive = !this.isActive;
      this.judge = !this.judge;
      this.fetTableHeight();
      if (this.judge == false) {
        this.MoreConditions = "更多条件";
      } else {
        this.MoreConditions = "收起条件";
      }
    },

    // 表格选择事件
    handleSelectionChange(e) {
      this.selectTableData = e;
    },

    /**
     * =========================按钮部分的方法=>开始??????????????????????????????????
     */

    //导出操作
    handleCommand_dc(command) {
      if (command == "dc") {
        // 导出
        this.exportBtn();
      } else if (command == "dcmb") {
        // 下载模板
        this.exportTemplateDownload();
      } else if (command == "scmb") {
        // 上传模板
        this.upLoadBtn();
      }
    },

    /**
     * =========================按钮部分的方法=>结束??????????????????????????????????
     */

    // ===============================================导出部分=开始======
    // 上传导出模板
    uploadSectionFile(param) {
      const file = param.file,
        fileName = file.name;

      // 根据后台需求数据格式
      const form = new FormData();
      // 文件对象
      form.append("file", file);
      // 本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
      form.append("fileName", fileName);
      let signArr = [
        {
          key: "fileName",
          val: fileName,
        },
      ];
      let sign = tools.getMD5Sign(signArr);
      form.append("sign", sign);
      // 项目封装的请求方法，下面做简单介绍
      Api.uploadFile(form)
        .then((res) => {
          if (res.data.status == "success") {
            let fileName = res.data.result.fileName;

            let signArr = [
              {
                key: "groupName",
                val: this.pageGroupName,
              },
              {
                key: "templateName",
                val: this.sortName,
              },
              {
                key: "fileName",
                val: fileName,
              },
            ];
            let sign_ = tools.getMD5Sign(signArr);
            Api.saveExportTemplate({
              groupName: this.pageGroupName,
              templateName: this.sortName,
              fileName: fileName,
              sign: sign_,
            }).then((res) => {
              this.$message.success(res.data.message || "更新成功");
              this.show_upload = false;
            });
          }
        })
        .catch((err) => {});
    },

    // 下载用户导出模板
    exportTemplateDownload() {
      var fieldName = this.fieldName;
      var head = this.head;
      if (head.length == 0) {
        let fieldNames = [];
        let heads = [];
        for (var i = 0; i < this.myTableHeard.length; i++) {
          if (this.myTableHeard[i].isShow) {
            fieldNames.push(this.myTableHeard[i].field);
            heads.push(this.myTableHeard[i].name);
          }
        }
        fieldName = fieldNames;
        head = heads;
      }
      //加密数组格式
      let signArr = [
        {
          key: "fieldName",
          val: fieldName,
        },
        {
          key: "head",
          val: head,
        },
      ];
      let sign = tools.getMD5Sign(signArr);
      let param = {
        fieldName: fieldName,
        head: head,
        sign: sign,
      };
      Api.exportTemplateDownload(param).then((res) => {
        let downloadurl = res.data.result.fileUrl || "";
        //下载(这里其实是直接访问)
        var a = document.createElement("a");
        a.setAttribute("href", downloadurl);
        a.setAttribute("target", "_blank");
        a.click();
      });
    },
    // 真正导出
    templateDataExport(customizedExportId) {
      let that = this;
      let datas = that.tableData;
      let parm = {
        customizedExportId: customizedExportId, //模板id
        className: that.className,
        data: datas,
      };
      let signarr = tools.getSignArr(parm);
      let signs_e = tools.getMD5Sign(signarr);

      Api.templateDataExport({
        customizedExportId: customizedExportId,
        className: that.className,
        data: JSON.stringify(datas),
        sign: signs_e,
      }).then((res) => {
        //下载(这里其实是直接访问)
        let downloadurl = res.data.result.fileUrl;
        var a = document.createElement("a");
        a.style.display = "none";
        a.href = downloadurl;
        a.target = "_blank";
        a.setAttribute("download", "综合查询.xlsx"); //指定下载后的文件名，防跳转
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
    },
    // 上传按钮
    upLoadBtn() {
      this.show_upload = true;
    },
    // 点击导出按钮
    exportBtn() {
      let that = this;
      let fieldName = [];
      let head = [];
      for (var i = 0; i < this.myTableHeard.length; i++) {
        if (this.myTableHeard[i].isShow) {
          fieldName.push(this.myTableHeard[i].field);
          head.push(this.myTableHeard[i].name);
        }
      }
      this.fieldName = fieldName;
      this.head = head;

      // 1,先获取用户导出模板
      Api.getUserExportTemplate({
        groupName: this.pageGroupName,
      }).then((res) => {
        if (res.data.result && res.data.result[0].customizedExportId) {
          // 有自定义模板

          let customizedExportId = res.data.result[0].customizedExportId;
          that.templateDataExport(customizedExportId);
        } else {
          //没有自定义模板,就是下载一个
          tools.dynamicExport(
            this.myTableHeard,
            this.className,
            this.tableData,
            "货币管理列表"
          );
        }
      });
    },
    // ===============================================导出部分=结束======

    /**
     * ******************************计算表格高度**开始*************************
     */
    // 重置table高度
    resetHeight() {
      return new Promise((resolve, reject) => {
        this.tableHeigth = 0;
        resolve();
      });
    },
    // 设置table高度
    fetTableHeight() {
      this.resetHeight().then((res) => {
        this.tableHeigth = this.getHeight();
        //console.log(this.tableHeigth + "表格高度");
        this.$nextTick(() => {
          this.$refs.mytable.doLayout();
        });
      });
    },
    // 获取表格高度
    getHeight() {
      let windonHeight = document.body.clientHeight;
      let queryHeigth;
      let btnListHeight = this.$refs.btnList.offsetHeight + 16; // 按钮列表  16==上下的margin
      if (this.judge == true) {
        queryHeigth = this.$refs.queryCriteria.offsetHeight; //查询框
      } else {
        queryHeigth = 50;
      }
      let pagsHeigth = 40; //分页组件
      var otherHeight = queryHeigth + btnListHeight + pagsHeigth + 20; //60是顶部导航 ,10==预留空位
      return Math.floor(windonHeight - otherHeight) || "50vh"; //
    },
    /**
     * ******************************计算表格高度**结束*************************
     */

    // 刷新页面==>
    reload_s() {
      this.reload(); //App.Vue里面的方法
    },

    // 获取数据
    getData() {
      let formData = {};
      formData.pageStart = this.pageNum;
      formData.pageTotal = this.size;
      Api.currencyList(formData).then((res) => {
        //console.log(res.data.result.data);
        if (res.data.status == "success") {
          this.className = res.data.result.className || "";
          this.tableData = res.data.result.data || [];
          this.total = res.data.result.pageCount || 0;
          this.fetTableHeight();
        }
      });
    },

    // 获取自定义的排序,没有就用默认的
    getGroupFindFieldSort() {
      let that = this;
      Api.getGroupFindFieldSort({
        groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
      }).then((res) => {
        let arr = res.data.result || [];
        if (arr.length > 0) {
          if (arr[0].sortObject) {
            that.myTableHeard = JSON.parse(arr[0].sortObject);
            this.sortName = arr[0].sortName;
          }
        } else {
          that.myTableHeard = that.tabelHeadeTitle;
        }
        let totalArr = [];
        //提取需要合计的字段
        for (var i = 0; i < that.myTableHeard.length; i++) {
          if (that.myTableHeard[i].isTotal) {
            totalArr.push(that.myTableHeard[i].field);
          }
        }
        that.totalArr = totalArr;
      });

      that.$nextTick(() => {
        that.$refs.mytable.doLayout();
      });
    },
    // 设置模板按钮
    myModel() {
      let that = this;
      that.show_sortTableHeard = true;
      that.tabelHeadeTitle = JSON.parse(JSON.stringify(that.myTableHeard));
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
      if (selectLength == that.tabelHeadeTitle.length) {
        that.allCheck = true;
      } else {
        that.allCheck = false;
      }
    },

    // 排序==>取消按钮
    cancelSortHeard() {
      this.show_sortTableHeard = false;
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
    },
    // 排序==>确认按钮
    confirmOk(e) {
      this.tabelHeadeTitle = e;
      this.myTableHeard = this.tabelHeadeTitle;
      let sortObject = this.myTableHeard;
      let signs = [
        {
          key: "sortName",
          val: this.sortName, //模板名称
        },
        {
          key: "groupName",
          val: this.pageGroupName, //页面标识
        },
        {
          key: "sortObject",
          val: JSON.stringify(sortObject),
        },
      ];

      let sign = tools.getMD5Sign(signs);

      let param = {
        // apiPath: apiList.api_packageList, //综合查询接口路径
        groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
        sortName: this.sortName, //综合查询接口路径
        sortObject: JSON.stringify(sortObject),
        sign: sign,
      };
      Api.pushFindFieldSort(param).then((res) => {
        this.$message.success(res.data.message || "操作成功");
        this.getGroupFindFieldSort();
      });
      this.show_sortTableHeard = false; //关闭弹窗
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
      this.show_sortTableHeard = false;
    },
    // 点击某一项
    checktab(idx, e) {
      let that = this;
      that.tabelHeadeTitle[idx].isShow = e;
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
      if (selectLength == that.tabelHeadeTitle.length) {
        that.allCheck = true;
      } else {
        that.allCheck = false;
      }
    },
    //全选
    checkAll(e) {
      let that = this;
      this.allCheck = e;
      for (var j = 0; j < this.tabelHeadeTitle.length; j++) {
        this.tabelHeadeTitle[j].isShow = e;
      }
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
    },
    //关闭弹窗
    closeDias(e) {
      this.show_sortTableHeard = e;
    },
    // 合计
    getSummaries(param) {
      let that = this;
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        for (var i = 0; i < that.totalArr.length; i++) {
          if (column.property == that.totalArr[i]) {
            if (!values.every((value) => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                  return tools.mathToFixed(tools.add(prev, curr), 2);
                } else {
                  return prev;
                }
              }, 0);
              sums[index] += "";
            } else {
              sums[index] = "---";
            }
          }
        }
      });
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
      return sums;
    },

    //分页
    handleSizeChange(val) {
      this.size = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getData();
    },
  },
};
</script>

<style scoped lang="scss">
.tableBox {
  height: calc(100% - 260px);
}
.colorStyle {
  color: #f00;
}
.mdTitName {
  height: 24px;
  border: 1px solid #d7d7d7;
  text-align: center;
  min-width: 100px;
}
.queryCriteria {
  .queryItem_one {
    .queryTextarea {
      border: 1px solid #d7d7d7;
    }
  }
  .timeBox {
    border: 1px solid #d7d7d7;
  }
}
.headerbox {
  width: 100%;
  display: flex;
  justify-content: center;
}
.footerbox {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.newForm {
  // margin-left: 80px;
  display: flex;
  flex-wrap: wrap;
  .queryItem1 {
    width: 30%;
    min-width: 240px;
    margin-top: 20px;
  }
}
.singleinp1 {
  min-width: 392px;
}
.HeadInputbox .item_right .singleinp1 .el-date-editor.el-input,
.HeadInputbox .item_right .singleinp1 .el-date-editor.el-input__inner {
  width: 100%;
}
.SingleChoicebox {
  width: 100%;
  padding: 0 20px;
  margin-top: 20px;
  box-sizing: border-box;
}
.el-input-number {
  width: 100%;
  height: 30px;
  line-height: 30px;
}
</style>
